import React, { useState } from 'react';
import _isEmpty from 'lodash/isEmpty';
import { FormikWizard } from 'formik-wizard';
import CardWithTitle from '../../../components/Layouts/cardWithTitle';
import Grid from '@material-ui/core/Grid';
import FormWrapper from '../../../components/FormControls/FormWrapper';
import GeneralInfo, { generalInfoInitialValues, generalInfoValidationSchema } from './forms/generalInfo';
import ResourcePricing, { resourcePricingInitialValues, resourcePricingValidationSchema } from './forms/resourcePricing';
import ResourceImages, { resourceImagesInitialValues, resourceImagesValidationSchema } from './forms/resourceImages';
import useInventory from '../../../components/hooks/useInventory';
import useToast from '../../../components/hooks/useToast';
import { useRouter } from '../../../components/hooks/useRouter';
import { urlPaths } from '../../../routes';
import { preFillObject } from '../../../utils/objects';

const InventoryAdd = ({ id }) => {
  const { saveItem, getItemDetails } = useInventory();
  const [itemData, setItemData] = useState({})

  if (id) {
    getItemDetails(id).then(response => setItemData(response));
  }

  const { push } = useRouter();
  const { success, error } = useToast();
  const STEPS = [
    {
      id: 'generalInfo',
      component: GeneralInfo,
      initialValues: preFillObject(generalInfoInitialValues, itemData),
      validationSchema: generalInfoValidationSchema,
    }, {
      id: 'resourcePricing',
      component: ResourcePricing,
      initialValues: preFillObject(resourcePricingInitialValues, itemData),
      validationSchema: resourcePricingValidationSchema,
    }, {
      id: 'resourceImages',
      component: ResourceImages,
      initialValues: preFillObject(resourceImagesInitialValues, itemData),
      validationSchema: resourceImagesValidationSchema,
    },
  ]
  return (
    <Grid container justify="center">
      <Grid item md={6} xs={12}>
        <CardWithTitle title="New Resource">
          <FormikWizard onSubmit={async values => {
            const savedItem = await saveItem(values);
            if (!_isEmpty(savedItem)) {
              success("Successfully saved!");
              setTimeout(() => push(urlPaths.inventory), 1000);
            } else {
              error("Something went wrong!");
            }
          }} steps={STEPS} render={FormWrapper} />
        </CardWithTitle>
      </Grid>
    </Grid>
  )
}

export default InventoryAdd;
