import React from 'react';
import './App.css';
import Routes from './routes';
import { ThemeProvider } from '@material-ui/core/styles';
import AppBarWithSidebar from './containers/topLevelContainer/AppBarWithSidebar';
import globalTheme from './globalTheme';
import { ToastProvider } from 'react-toast-notifications';
import { AuthProvider } from './useAuth';

const App = () => {
  return (
    <div className="App">
      <ThemeProvider theme={globalTheme}>
        <ToastProvider
          autoDismiss
          autoDismissTimeout={15000}
          placement="bottom-left"
        >
          <AuthProvider>
            <AppBarWithSidebar>
              <Routes />
            </AppBarWithSidebar>
          </AuthProvider>
        </ToastProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
