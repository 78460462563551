import React from 'react'
import { Typography } from '@material-ui/core';
import SignInSignUpLayout from '../../../components/Layouts/signInSignUpLayout';

const Welcome = () => {
  return (
    <SignInSignUpLayout style={{ minHeight: 'unset' }} noFooter >
      <Typography>
        Thank you for giving us a try, you need to have a verified account to continue.
      </Typography>
    </SignInSignUpLayout>
  )
}

export default Welcome
