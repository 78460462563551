import React from 'react';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Link from '@material-ui/core/Link';
import CardWithTitle from '../../../components/Layouts/cardWithTitle';
import useReservation from '../../../components/hooks/useReservation';
import { Table, Chip } from '@material-ui/core';
import { useRouter } from '../../../components/hooks/useRouter';
import { urlPaths } from '../../../routes';

const ReservationList = () => {
  const { push } = useRouter();
  const { list } = useReservation();
  return (
    <div>
      <CardWithTitle title="Reservation list">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Resource</TableCell>
              <TableCell>Customer</TableCell>
              <TableCell>Note</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map(row => (
              <TableRow key={row.id}>
                <TableCell>
                  <Link onClick={() => push(`${urlPaths.reservation}/view/${row.id}`)}>{row.id}</Link>
                </TableCell>
                <TableCell>{row.resource}</TableCell>
                <TableCell>{row.customer}</TableCell>
                <TableCell>{row.note}</TableCell>
                <TableCell>
                  <Chip className="text-uppercase" size="small" label={row.status} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardWithTitle>
    </div>
  )
}

export default ReservationList;
