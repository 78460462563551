import React from 'react';
import { connect } from 'formik';
import TextField from '../../../components/FormControls/TextField';
import * as yup from 'yup';
import { Typography } from '@material-ui/core';

const countries = [
  {
    value: 'ph',
    label: 'Philippines',
  },
];

export const addressDetailsInitialValues = {
  address: '',
  city: '',
  zip: '',
  country: 'ph',
}

export const addressDetailsValidationSchema = yup.object().shape({
  address: yup.string().trim().required(),
  city: yup.string().trim().required(),
  zip: yup.string().trim().required(),
  country: yup.string().trim().required(),
})

const AddressDetails = () => {
  return (
    <>
      <Typography className="mb-3">Address information</Typography>
      <TextField name="address" label="Address" />
      <TextField name="city" label="City" />
      <TextField name="zip" label="ZIP" type="number" />
      <TextField name="country" select={countries}  label="Country" disabled />
    </>
  )
}

export default connect(AddressDetails);
