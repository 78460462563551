import { createMuiTheme } from '@material-ui/core/styles';

export const colors = {
  appBackgroundColor: '#DBDCEB',
  lightViolet: '#f6f4fc',
  primaryThemeColor: '#849EF9',
  primaryTextColor: '#444',
  primary: {
    textColor: '#444',
  },
}

const globalTheme = createMuiTheme({
  palette: {
    primary: {
      500: colors.primaryThemeColor,
    },
  },
  typography: {
    fontFamily: '"Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
  },
  overrides: {
    MuiPaper: {
      root: {
        color: colors.primaryTextColor,
      },
    },
    MuiTableCell: {
      root: {
        color: colors.primaryTextColor,
      },
      head: {
        color: 'inherit',
        fontWeight: 'bold',
        fontSize: '0.8125rem',
      },
      body: {
        color: 'inherit',
      },
    },
    MuiButton: {
      root: {
        color: colors.primaryTextColor,
      },
      contained: {
        boxShadow: 'none',
        padding: '.5rem 2rem',
        borderRadius: '0.25rem',
      },
    },
    MuiLink: {
      root: {
        color: '#6E83D0',
      },
      underlineHover: {
        '&:hover': {
          cursor: 'pointer',
        }
      }
    },
    MuiTypography: {
      root: {
        color: colors.primary.textColor,
      }
    },
    MuiFormGroup: {
      row: {
        '& label': {
          fontSize: 14,
          color: colors.primary.textColor,
        }
      }
    },
    MuiFormControl: {
      marginDense: {
        marginTop: 4,
      }
    },
    MuiFormHelperText: {
      contained: {
        margin: '0.25rem 0',

        '&::first-letter': {
          textTransform: 'uppercase',
        }
      }
    }
  }
});

export default globalTheme;
