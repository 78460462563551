import { useAuth } from '../../useAuth';
import { useRouter } from '../../components/hooks/useRouter';
import { urlPaths } from '../../routes';
import { useEffect } from 'react';

export const RequireAuth = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const { push, pathname, basePathname } = useRouter();

  useEffect(() => {
    const next = pathname ? pathname : '';
    const urlToRedirect = `${urlPaths.login}?next=${next}`;
    if (!isAuthenticated && basePathname !== urlPaths.login && pathname !== urlToRedirect) {
      push(urlToRedirect);
    }
  }, [isAuthenticated, basePathname, pathname, push])

  return isAuthenticated && children
}

