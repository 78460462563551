import React from 'react';
import _map from 'lodash/map';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import CalendarToday from '@material-ui/icons/CalendarToday';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PeopleIcon from '@material-ui/icons/People';
import BarChartIcon from '@material-ui/icons/BarChart';
import StorageIcon from '@material-ui/icons/Storage';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { urlPaths } from '../../routes';
import { useRouter } from '../../components/hooks/useRouter';
import classNames from 'classnames';

export const MainListItems = ({ classes }) => {
  const { push, basePathname } = useRouter();
  const items = [
    {
      text: "Reservation",
      icon: <CalendarToday />,
      path: urlPaths.reservation,
    },
    // {
    //   text: "Dashboard",
    //   icon: <DashboardIcon />,
    //   path: urlPaths.dashboard,
    // }, {
    //   text: "Orders",
    //   icon: <ShoppingCartIcon />,
    //   path: 'urlPaths.dashboard',
    // }, {
    //   text: "Customers",
    //   icon: <PeopleIcon />,
    //   path: 'urlPaths.dashboard',
    // }, {
    //   text: "Reports",
    //   icon: <BarChartIcon />,
    //   path: 'urlPaths.dashboard',
    // }, 
    {
      text: "Inventory",
      icon: <StorageIcon />,
      path: urlPaths.inventory,
    },
    {
      text: "Customers",
      icon: <PeopleIcon />,
      path: urlPaths.customers,
    }
  ]
  return <div>
    {
      _map(items, ({ text, icon, path }, key) => {
        return <ListItem
          key={`mm-${key}`}
          button
          onClick={() => push(path)}
          className={classNames(basePathname === path && classes.activeMenu)}>
          <ListItemIcon>
            {icon}
          </ListItemIcon>
          <ListItemText primary={text} />
        </ListItem>
      })
    }
  </div>
}

export const secondaryListItems = (
  <div>
    {/* <ListSubheader inset>Saved reports</ListSubheader>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Current month" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Last quarter" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Year-end sale" />
    </ListItem> */}
  </div>
);
