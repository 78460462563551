import React from 'react';
import { connect } from 'formik';
import TextField from '../../../../components/FormControls/TextField';
import * as yup from 'yup';

const currencies = [
  {
    value: 'quantity',
    label: 'Quantity',
  },
];

export const resourcePricingInitialValues = {
  price: '',
  priceUnit: 'quantity',
}

export const resourcePricingValidationSchema = yup.object().shape({
  price: yup.string().trim().required('Price is required'),
  priceUnit: yup.string().trim().required('Pricing Unit is required'),
})

const ResourcePricing = () => {
  return (
    <>
      <TextField name="price" label="Price" type="number" />
      <TextField name="priceUnit" select={currencies}  label="Pricing Unit" disabled />
    </>
  )
}

export default connect(ResourcePricing);
