import React from 'react';
import { connect } from 'formik';
import TextField from '../../../../components/FormControls/TextField';
import * as yup from 'yup';

export const generalInfoInitialValues = {
  resource: '',
  quantity: 1,
  description: '',
}

export const generalInfoValidationSchema = yup.object().shape({
  resource: yup.string().trim().required('Resource is required'),
  quantity: yup.number().max(10).required('Resource Quantity is required'),
})

const GeneralInfo = () => {
  return (
    <>
      <TextField name="resource" label="Resource" infoText="Name of item" />
      <TextField name="quantity" type="number" label="Resource Quantity" />
      <TextField name="description" multiline type="textarea" label="Description" />
    </>
  )
}

export default connect(GeneralInfo);
