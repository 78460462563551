import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CardWithTitle from '../../../components/Layouts/cardWithTitle';
import useCustomer from '../../../components/hooks/useCustomer';
import { useRouter } from '../../../components/hooks/useRouter';
import { urlPaths } from '../../../routes';
import { Link, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, Button } from '@material-ui/core';

const CustomerList = () => {
  const { list, deleteCustomer } = useCustomer();
  const { push } = useRouter();
  const [toDelete, setToDelete] = useState('');
  console.log('list', list)
  return (
    <div>
      <CardWithTitle title="Customer list">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Last name</TableCell>
              <TableCell>First name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map(row => (
              <TableRow key={row.id}>
                <TableCell >
                  <Link onClick={() => push(`${urlPaths.customers}/view/${row.id}`)}>{row.id}</Link>
                </TableCell>
                <TableCell>{row.firstname}</TableCell>
                <TableCell>{row.lastname}</TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell>+63{row.phone}</TableCell>
                <TableCell>
                  <Link onClick={() => push(`${urlPaths.customers}/update/${row.id}`)}><EditIcon fontSize="small" /></Link>
                  <DeleteIcon fontSize="small" color="error" onClick={() => setToDelete(row.id)} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardWithTitle>
      <Dialog
        open={!!toDelete}
        onClose={() => setToDelete('')}
      >
        <DialogTitle id="alert-dialog-title">Delete</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setToDelete('')}>
            Close
          </Button>
          <Button onClick={() => {
            deleteCustomer(toDelete).then(() => setToDelete(''))
          }} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default CustomerList;
