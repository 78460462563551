import React from 'react';
import { Route, Switch } from "react-router-dom";
import Dashboard from './containers/dashboard/dashboard';
import Login from './containers/login/login';
import Register from './containers/register/register';
import Inventory from './containers/inventory/inventory';
import Enrollment from './containers/enrollment/enrollment';
import Reservation from './containers/reservation/reservation'
import Customers from './containers/customers/customers'
import { RequireAuth } from './containers/topLevelContainer/requireAuth';

export const urlPaths = {
	login: '/login',
	register: '/register',
	dashboard: '/dashboard',
	inventory: '/inventory',
	enrollment: '/enrollment',
	reservation: '/reservation',
	customers: '/customers',
}

export const urlPathsWithParams = {
	inventory: `${urlPaths.inventory}/:page(add|view|update)?/:id?`,
	reservation: `${urlPaths.reservation}/:page(new|view|update)?/:id?`,
	customers: `${urlPaths.customers}/:page(add|view|update)?/:id?`,
}

export const pageTitles = {
	[urlPaths.dashboard]: 'Dashboard',
	[urlPaths.inventory]: 'Inventory',
	[urlPaths.reservation]: 'Reservation',
	[urlPaths.customers]: 'Customers',
}

/**
 * Page navigation for pages that has sub pages
 */
export const pageNavigation = {
	[urlPaths.inventory]: [
		{
			title: "Items",
			path: urlPaths.inventory,
		}, {
			title: "New item",
			path: `${urlPaths.inventory}/add`,
		}
	],
	[urlPaths.reservation]: [
		{
			title: "Reservation",
			path: urlPaths.reservation,
		}, {
			title: "New reservation",
			path: `${urlPaths.reservation}/new`,
		}
	], 
	[urlPaths.customers]: [
		{
			title: "Customers",
			path: urlPaths.customers,
		}, {
			title: "Add customer",
			path: `${urlPaths.customers}/add`,
		}
	]
}

const Routes = () => {
	return (
		<Switch>
			<Route path={urlPaths.login} component={Login} />
			<Route path={urlPaths.register} component={Register} />
			<RequireAuth>
				<Route path={urlPaths.enrollment} component={Enrollment} />
				<Route path={urlPaths.dashboard} component={Dashboard} />
				<Route path={urlPathsWithParams.inventory} component={Inventory} />
				<Route path={urlPathsWithParams.reservation} component={Reservation} />
				<Route path={urlPathsWithParams.customers} component={Customers} />
			</RequireAuth>
		</Switch>
	)
}

export default Routes;
