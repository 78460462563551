import React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { colors } from '../../globalTheme';

const CardWithTitle = ({ children, title }) => {
  return (
    <Paper elevation={0} style={{ borderRadius: 0 }} >
      <div style={{ padding: '0.75rem', backgroundColor: colors.lightViolet }}>
        {title && <Typography variant="body2" >
          {title}
        </Typography>}
      </div>
      <div className="p-3">
        {children}
      </div>
    </Paper>
  )
}

export default CardWithTitle;
