import React from 'react';
import { connect } from 'formik';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import * as yup from 'yup';
import ImageUploader from 'react-images-upload';
import classNames from 'classnames';
import { FormHelperText } from '@material-ui/core';

export const resourceImagesInitialValues = {
  coverPhoto: [],
  galleryPhoto: [],
}

export const resourceImagesValidationSchema = yup.object().shape({
  coverPhoto: yup.array().required("Cover Photo is required"),
})

const ResourceImages = ({ formik }) => {

  const onPictureSelect = (model, pictures) => {
    const fileNames = _map(pictures, pic => {
      return pic.name;
    })
    formik.setFieldValue(model, fileNames);
  }

  const hasCoverImage = !_isEmpty(formik.values.coverPhoto);
  const coverPhotoError = _get(formik, "errors.coverPhoto", '');

  return (
    <>
      <ImageUploader
        singleImage
        withIcon={!hasCoverImage}
        withPreview
        buttonText='Choose Cover Image'
        buttonClassName={classNames(hasCoverImage && 'd-none')}
        onChange={pictures => onPictureSelect("coverPhoto", pictures)}
        imgExtension={['.jpg', 'jpeg', '.gif', '.png', '.gif']}
        maxFileSize={5242880}
        label={hasCoverImage ? 'Cover photo' : 'Max file size: 5mb, accepted: jpg|gif|png'}
      />
      <FormHelperText error>{coverPhotoError}</FormHelperText>
      <ImageUploader
        withIcon
        withPreview
        buttonText='Choose Gallery Image'
        onChange={pictures => onPictureSelect("galleryPhoto", pictures)}
        imgExtension={['.jpg', 'jpeg', '.gif', '.png', '.gif']}
        maxFileSize={5242880}
      />
    </>
  )
}

export default connect(ResourceImages);
