import React from 'react';
import { connect } from 'formik';
import TextField from '../../../components/FormControls/TextField';
import * as yup from 'yup';
import { Typography } from '@material-ui/core';

export const identityDetailsInitialValues = {
  id: '',
  firstname: '',
  lastname: '',
  birthdate: '',
}

export const identityDetailsValidationSchema = yup.object().shape({
  firstname: yup.string().trim().required(),
  lastname: yup.string().trim().required(),
  birthdate: yup.string().trim().required(),
})

const IdentityDetails = () => {
  return (
    <>
      <Typography className="mb-3">Basic Information</Typography>
      <TextField name="firstname" label="First name" />
      <TextField name="lastname" label="Last name" />
      <TextField label="Birthdate" name="birthdate" type="date" />
    </>
  )
}

export default connect(IdentityDetails);
