import React, { useState } from 'react';
import _isEmpty from 'lodash/isEmpty';
import { FormikWizard } from 'formik-wizard';
import CardWithTitle from '../../../components/Layouts/cardWithTitle';
import Grid from '@material-ui/core/Grid';
import FormWrapper from '../../../components/FormControls/FormWrapper';
import IdentityDetails, { identityDetailsInitialValues, identityDetailsValidationSchema } from '../../enrollment/enrollmentForms/identityDetails';
import AddressDetails, { addressDetailsInitialValues, addressDetailsValidationSchema } from '../../enrollment/enrollmentForms/addressDetails';
import ContactDetails, { contactDetailsInitialValues, contactDetailsValidationSchema } from '../../enrollment/enrollmentForms/contactDetails';
import useCustomer from '../../../components/hooks/useCustomer';
import useToast from '../../../components/hooks/useToast';
import { useRouter } from '../../../components/hooks/useRouter';
import { urlPaths } from '../../../routes';
import { preFillObject } from '../../../utils/objects';

const InventoryAdd = ({ id }) => {
  const { saveCustomer, getCustomerDetails } = useCustomer();
  const [itemData, setItemData] = useState({})

  if (id) {
    getCustomerDetails(id).then(response => setItemData(response));
  }

  const { push } = useRouter();
  const { success, error } = useToast();
  const STEPS = [
    {
      id: 'identityDetails',
      component: IdentityDetails,
      initialValues: preFillObject(identityDetailsInitialValues, itemData),
      validationSchema: identityDetailsValidationSchema,
    },
    {
      id: 'addressDetails',
      component: AddressDetails,
      initialValues: preFillObject(addressDetailsInitialValues, itemData),
      validationSchema: addressDetailsValidationSchema,
    },
    {
      id: 'contactDetails',
      component: ContactDetails,
      initialValues: preFillObject(contactDetailsInitialValues, itemData),
      validationSchema: contactDetailsValidationSchema,
    },
  ];
  return (
    <Grid container justify="center">
      <Grid item md={6} xs={12}>
        <CardWithTitle title="Add customer">
          <FormikWizard onSubmit={async values => {
            const savedCustomer = await saveCustomer(values);
            if (!_isEmpty(savedCustomer)) {
              success(`Successfully ${id ? "updated!" : "saved!"}`);
              setTimeout(() => push(urlPaths.customers), 1000);
            } else {
              error("Something went wrong!");
            }
          }} steps={STEPS} render={FormWrapper} />
        </CardWithTitle>
      </Grid>
    </Grid>
  )
}

export default InventoryAdd;
