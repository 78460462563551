import { useState, useMemo } from 'react';
import uuid from 'uuid';
import _filter from 'lodash/filter';
import _map from 'lodash/map';
import _first from 'lodash/first';
import _get from 'lodash/get';
import { flattenObject } from '../../utils/objects';

const generateId = () => uuid().substring(0, 8);

const mockList = [
  {
    id: '280162e9',
    customer: 'Decker, Damon',
    resource: 'Car',
    note: 'Reiciendis et dolores ',
    status: 'reserved',
    zip: '36149',
    country: 'ph',
    phone: '9121231234',
    email: 'damondecker@example.com',
  },
  {
    id: 'e9f5415c',
    customer: 'Adkins, Tanek',
    resource: 'Camera',
    note: '',
    status: 'on-rent',
    zip: '35691',
    country: 'ph',
    phone: '9971234567',
    email: 'tanek.adkins@gmail.com',
  },
  {
    id: '563a3ff6',
    customer: 'Byers, Samuel',
    resource: 'Camera',
    note: 'Assumenda odio tempo',
    status: 'on-rent',
    zip: '21784',
    country: 'ph',
    phone: '9551987654',
    email: 'samuelb@gmail.com',
  },
]

const useCustomer = () => {
  const [list, setList] = useState(mockList);
  const [loading, setLoading] = useState();

  const saveReservation = item => {
    setLoading(true);
    return new Promise(resolve => {
      const flattenItem = flattenObject(item);
      const id = _get(flattenItem, 'id');
      let localList = list;
      if (id) {
        // remove customer here to be able to save updated details
        localList = _filter(list, i => i.id !== id);
      }
      const newItem = { ...flattenItem, id: id || generateId() };
      localList.push(newItem);
      setTimeout(() => setList(localList), 250);
      setLoading(false);
      resolve(item);
    })
  }

  const getReservationDetails = id => {
    return new Promise(resolve => {
      const item = _filter(list, { id });
      resolve(_first(item));
    })
  }

  const voidReservation = id => {
    return new Promise(resolve => {
      const newList = _filter(list, i => i.id !== id);
      setList(newList);
      resolve();
    })
  }

  return { loading, list, saveReservation, getReservationDetails, voidReservation }
}

export default useCustomer;
