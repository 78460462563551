import React from 'react';
import { FormikWizard } from 'formik-wizard';
import CardWithTitle from '../../components/Layouts/cardWithTitle';
import Grid from '@material-ui/core/Grid';
import FormWrapper from '../../components/FormControls/FormWrapper';
import Welcome from './enrollmentForms/welcome';
import IdentityDetails, { identityDetailsInitialValues, identityDetailsValidationSchema } from './enrollmentForms/identityDetails';
import AddressDetails, { addressDetailsInitialValues, addressDetailsValidationSchema } from './enrollmentForms/addressDetails';
import ContactDetails, { contactDetailsInitialValues, contactDetailsValidationSchema } from './enrollmentForms/contactDetails';
import * as yup from 'yup';
import { useAuth } from '../../useAuth';
import { Typography, Link } from '@material-ui/core';

const Enrollment = () => {
  const { signOut } = useAuth();
  const STEPS = [
    {
      id: 'welcome',
      component: Welcome,
      initialValues: {},
      validationSchema: yup.object().shape({}),
    },
    {
      id: 'identityDetails',
      component: IdentityDetails,
      initialValues: identityDetailsInitialValues,
      validationSchema: identityDetailsValidationSchema,
    },
    {
      id: 'addressDetails',
      component: AddressDetails,
      initialValues: addressDetailsInitialValues,
      validationSchema: addressDetailsValidationSchema,
    },
    {
      id: 'contactDetails',
      component: ContactDetails,
      initialValues: contactDetailsInitialValues,
      validationSchema: contactDetailsValidationSchema,
    },
  ]
  return (
    <Grid container justify="center" alignItems="center" style={{ height: '100vh' }}>
      <Grid item md={6} xs={12}>
        <CardWithTitle title={
          <div className="d-flex justify-content-between">
            <Typography>Enrollment</Typography>
            <Link onClick={signOut}>Logout</Link>
          </div>}>
          <FormikWizard onSubmit={e => console.log('onSubmit:e', e)} steps={STEPS} render={FormWrapper} />
        </CardWithTitle>
      </Grid>
    </Grid>
  )
}

export default Enrollment;
