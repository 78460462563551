import React from 'react';
import _isEmpty from 'lodash/isEmpty';
import { FormikWizard } from 'formik-wizard';
import CardWithTitle from '../../../components/Layouts/cardWithTitle';
import Grid from '@material-ui/core/Grid';
import FormWrapper from '../../../components/FormControls/FormWrapper';
import GeneralInfo, { generalInfoInitialValues, generalInfoValidationSchema } from './forms/generalInfo';
import DeliveryInfo, { deliveryInfoInitialValues, deliveryInfoValidationSchema } from './forms/deliveryInfo';
import useInventory from '../../../components/hooks/useInventory';
import useToast from '../../../components/hooks/useToast';
import { useRouter } from '../../../components/hooks/useRouter';
import { urlPaths } from '../../../routes';

const InventoryAdd = () => {
  const { saveItem } = useInventory();
  const { push } = useRouter();
  const { success, error } = useToast();
  const STEPS = [
    // {
    //   id: 'generalInfo',
    //   component: GeneralInfo,
    //   initialValues: generalInfoInitialValues,
    //   validationSchema: generalInfoValidationSchema,
    // },
    {
      id: 'delivery',
      component: DeliveryInfo,
      initialValues: deliveryInfoInitialValues,
      validationSchema: deliveryInfoValidationSchema,
    },
  ]
  return (
    <Grid container justify="center">
      <Grid item md={8} xs={12}>
        <CardWithTitle title="New rent/reservation">
          <FormikWizard onSubmit={async values => {
            const savedItem = await saveItem(values);
            if (!_isEmpty(savedItem)) {
              success("Successfully saved!");
              setTimeout(() => push(urlPaths.inventory), 1000);
            } else {
              error("Something went wrong!");
            }
          }} steps={STEPS} render={FormWrapper} />
        </CardWithTitle>
      </Grid>
    </Grid>
  )
}

export default InventoryAdd;
