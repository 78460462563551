import _first from 'lodash/first';
import React from 'react';
import ButtonSubmit from '../ButtonSubmit';

const FormWrapper = ({
  children,
  isLastStep,
  goToPreviousStep,
  canGoBack,
  actionLabel,
  steps,
  currentStep,
}) => {
  const isFirstStep = _first(steps) === currentStep;

  return (
    <div>
      {children}
      <div className="d-flex justify-content-end mt-5">
        {!isFirstStep && <ButtonSubmit className="mr-1" style={{ color: 'unset' }} type="button" variant="outlined" onClick={goToPreviousStep} disabled={!canGoBack} >
          Previous
        </ButtonSubmit>}
        <ButtonSubmit type="submit">
          {actionLabel || (isLastStep ? 'Submit' : 'Continue')}
        </ButtonSubmit>
      </div>
    </div>
  )
}

export default FormWrapper;
