import React from 'react';
import _get from 'lodash/get';
import _map from 'lodash/map';
import { connect, Field } from 'formik';
import { TextField, FormGroup, FormLabel, Tooltip, MenuItem } from '@material-ui/core';
import LogoutIcon from '@material-ui/icons/InfoOutlined';

const CustomTextField = ({ name, label, infoText, select, formik, ...otherProps }) => {
  const error = _get(formik, `errors.${name}`, '');

  return (
    <FormGroup row className="mb-3">
      <div className="d-flex w-100">
        <FormLabel style={{ flex: 1 }}>{label}</FormLabel>
        {infoText && <Tooltip title={infoText}><LogoutIcon style={{ fontSize: '0.75rem' }} /></Tooltip>}
      </div>
      {
        !!select ? <Field
          select
          name={name}
          as={TextField}
          variant="outlined"
          fullWidth
          margin="dense"
          error={!!error}
          helperText={error}
          {...otherProps}
        >{_map(select, option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}</Field> : <Field
            name={name}
            as={TextField}
            variant="outlined"
            fullWidth
            margin="dense"
            error={!!error}
            helperText={error}
            {...otherProps}
          />
      }

    </FormGroup>
  )
}

export default connect(CustomTextField);
