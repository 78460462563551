import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CardWithTitle from '../../../components/Layouts/cardWithTitle';
import useInventory from '../../../components/hooks/useInventory';
import { useRouter } from '../../../components/hooks/useRouter';
import { urlPaths } from '../../../routes';
import { Link, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, Button } from '@material-ui/core';

const InventoryList = () => {
  const { list, deleteItem } = useInventory();
  const { push } = useRouter();
  const [toDelete, setToDelete] = useState('');
  return (
    <div>
      <CardWithTitle title="Inventory list">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Resource</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell align="right">Price</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map(row => (
              <TableRow key={row.id}>
                <TableCell >
                  <Link onClick={() => push(`${urlPaths.inventory}/view/${row.id}`)}>{row.id}</Link>
                </TableCell>
                <TableCell>{row.resource}</TableCell>
                <TableCell>{row.description}</TableCell>
                <TableCell>{row.quantity}</TableCell>
                <TableCell align="right">{row.price}</TableCell>
                <TableCell>
                  <Link onClick={() => push(`${urlPaths.inventory}/update/${row.id}`)}><EditIcon fontSize="small" /></Link>
                  <DeleteIcon fontSize="small" color="error" onClick={() => setToDelete(row.id)} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardWithTitle>
      <Dialog
        open={!!toDelete}
        onClose={() => setToDelete('')}
      >
        <DialogTitle id="alert-dialog-title">Delete</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setToDelete('')}>
            Close
          </Button>
          <Button onClick={() => {
            deleteItem(toDelete).then(() => setToDelete(''))
          }} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default InventoryList;
