import React from 'react';
import { connect } from 'formik';
import TextField from '../../../../components/FormControls/TextField';
import * as yup from 'yup';
import useCustomer from '../../../../components/hooks/useCustomer';

const deliveryMode = [
  {
    value: 'walk-in',
    label: 'Walk-in',
  },
];

const status = [
  {
    value: 'on-rent',
    label: 'On-rent',
  }
]

export const deliveryInfoInitialValues = {
  customer: '',
  status: 'on-rent',
  deliveryMode: 'walk-in',
}

export const deliveryInfoValidationSchema = yup.object().shape({
  customer: yup.string().trim().required(),
  deliveryMode: yup.string().trim().required('Delivery is required'),
})

const DeliveryInfo = () => {
  const { list: customerList } = useCustomer();
  return (
    <>
      <TextField name="customer" select={customerList}  label="Customer" />
      <TextField name="status" select={status}  label="Status" />
      <TextField name="deliveryMode" select={deliveryMode}  label="Delivery Mode" disabled />
    </>
  )
}

export default connect(DeliveryInfo);
