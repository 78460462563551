import React, { useState, useEffect, useContext, createContext } from "react";
import * as firebase from "firebase/app";
import "firebase/auth";
import useToast from './components/hooks/useToast';
import localStorage from 'local-storage';

// Add your Firebase credentials
firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
});

const authContext = createContext();

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function AuthProvider({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loggingIn, setLoggingIn] = useState(false);
  const { success, error } = useToast();

  // Wrap any Firebase methods we want to use making sure ...
  // ... to save the user to state.
  const signIn = (email, password) => {
    setLoggingIn(true);
    return firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(response => {
        success("Signed in successfully");
        setUser(response.user);
      })
      .catch(({ code }) => {
        if (code && code === "auth/user-not-found") {
          error("User not found!")
        }
      })
      .finally(() => setLoggingIn(false))
  };

  const signUp = (email, password) => {
    setLoggingIn(true);
    return firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(response => {
        setUser(response.user);
        setLoggingIn(false);
        return response.user;
      });
  };

  const signOut = () => {
    setLoading(true);
    return firebase
      .auth()
      .signOut()
      .then(() => {
        setLoading(false);
        setUser(false);
      });
  };

  const sendPasswordResetEmail = email => {
    setLoading(true);
    return firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        setLoading(false);
        return true;
      });
  };

  const confirmPasswordReset = (code, password) => {
    setLoading(true);
    return firebase
      .auth()
      .confirmPasswordReset(code, password)
      .then(() => {
        setLoading(false);
        return true;
      });
  };

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  useEffect(() => {
    setLoading(true);
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      setLoading(false);
      if (user) {
        setUser(user);
        localStorage('user', user);
      } else {
        setUser(false);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  // Return the user object and auth methods
  return {
    user,
    isAuthenticated: !!user,
    loading,
    loggingIn,
    signIn,
    signUp,
    signOut,
    sendPasswordResetEmail,
    confirmPasswordReset
  };
}