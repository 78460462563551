import React from 'react';
import { connect } from 'formik';
import _get from 'lodash/get';
import TextField from '../../../../components/FormControls/TextField';
import * as yup from 'yup';
import useInventory from '../../../../components/hooks/useInventory';
import { Typography, Grid } from '@material-ui/core';
import moment from 'moment';
import { dateFormat } from '../../../../utils/constants';

export const generalInfoInitialValues = {
  resource: '',
  quantity: 1,
  notes: '',
  startDate: moment().format(dateFormat),
  endDate: moment().add(1, 'days').format(dateFormat),
}

export const generalInfoValidationSchema = yup.object().shape({
  resource: yup.string().trim().required('Resource is required'),
  quantity: yup.string().trim().required('Resource Quantity is required'),
})

const GeneralInfo = ({ formik }) => {
  const { resourceAsOption } = useInventory();
  const resource = _get(formik, 'values.resource', '');
  console.log('formik.values', formik.values);
  return (
    <>
      <Typography className="mb-3">Reservation details  </Typography>
      <Grid container spacing="1">
        <Grid item xs={5}>
          <TextField name="startDate" label="Start Date" type="date" />
        </Grid>
        <Grid item xs={5}>
          <TextField name="endDate" label="End Date" type="date" />
        </Grid>
        <Grid item xs={2}>
          <TextField name="quantity" type="number" label="Quantity" disabled />
        </Grid>
      </Grid>
      <TextField name="resource" label="Resource" select={resourceAsOption} />
      {resource && <>
        <TextField name="notes" multiline type="textarea" label="Notes" />
      </>}
    </>
  )
}

export default connect(GeneralInfo);