import React, { useState } from 'react';
import _keys from 'lodash/keys';
import _each from 'lodash/each';
import _map from 'lodash/map';
import _upperFirst from 'lodash/upperFirst';
import _omit from 'lodash/omit';
import useInventory from '../../../components/hooks/useInventory';
import { Table, TableBody, TableRow, TableCell, Grid, Paper } from '@material-ui/core';

const shaper = data => {
  let items = [];
  _each(_keys(data), key => {
    items.push({
      title: _upperFirst(key),
      body: data[key],
    })
  })
  return items;
}

const InventoryItemView = ({ id }) => {
  const [data, setData] = useState({});
  const { getItemDetails } = useInventory();
  getItemDetails(id).then(val => {
    setData(val);
  })
  const shapedData = shaper(_omit(data, ['coverPhoto', 'galleryPhoto', 'priceUnit']));
  return (
    <Grid container justify="center">
      <Grid item xs={12} md={8}>
        <Paper elevation={0} square>
          <Table>
            <TableBody>
              {
                _map(shapedData, row => {
                  return <TableRow key={row.title}>
                    <TableCell className="font-weight-bold">{row.title}</TableCell>
                    <TableCell>{row.body}</TableCell>
                  </TableRow>
                })
              }
            </TableBody>
          </Table>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default InventoryItemView;
