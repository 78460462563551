import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import _defaultTo from 'lodash/defaultTo';
import ButtonSubmit from '../../components/ButtonSubmit';
import CustomFormik from '../../components/FormControls/CustomFormik';
import TextField from '../../components/FormControls/TextField';
import { useRouter } from '../../components/hooks/useRouter';
import SignInSignUpLayout from '../../components/Layouts/signInSignUpLayout';
import { urlPaths } from '../../routes';
import { useAuth } from '../../useAuth';

const useStyles = makeStyles(theme => ({
  submit: {
    margin: theme.spacing(1),
  },
}));

const SignIn = () => {
  const classes = useStyles();
  const { loggingIn, signIn, isAuthenticated } = useAuth();
  const { push, pathname, query } = useRouter();

  useEffect(() => {
    const urlToRedirect = _defaultTo(query.next, urlPaths.inventory);
    if (isAuthenticated && pathname !== urlToRedirect) {
      push(urlPaths.dashboard);
      setTimeout(() => push(urlToRedirect), 50);
    }
  }, [isAuthenticated, query, pathname, push])

  return <SignInSignUpLayout>
    <CustomFormik
      onSubmit={async ({ email, password }) => {
        const user = await signIn(email, password);
        if (user && user.uid) {
          push(urlPaths.dashboard)
        }
      }}
      initialValues={{ email: '' }}>
      <>
        <TextField
          label="Email Address"
          name="email"
          autoComplete="email"
          required
        />
        <TextField
          name="password"
          label="Password"
          type="password"
          autoComplete="current-password"
          required
        />
        <Grid container spacing={1} direction="column" alignItems="center">
          <Grid item xs>
            <ButtonSubmit
              loading={loggingIn}
              className={classes.submit}
            >
              Sign In
          </ButtonSubmit></Grid>
          <Grid item xs>
            <Link href="#" variant="body2">
              Forgot password?
              </Link>
          </Grid>
          <Grid item>
            <Link onClick={() => push(urlPaths.register)} variant="body2">
              {"Don't have an account? Sign Up"}
            </Link>
          </Grid>
        </Grid>
      </>
    </CustomFormik>
  </SignInSignUpLayout>
}

export default SignIn;
