import React from 'react';
import { connect } from 'formik';
import _get from 'lodash/get';
import TextField from '../../../components/FormControls/TextField';
import * as yup from 'yup';
import { Typography, InputAdornment } from '@material-ui/core';
import localStorage from 'local-storage';

export const contactDetailsInitialValues = {
  phone: '',
  email: _get(localStorage('user'), 'email'),
}

export const contactDetailsValidationSchema = yup.object().shape({
  phone: yup.string().trim().required(),
  email: yup.string().trim().required(),
})

const ContactDetails = () => {
  return (
    <>
      <Typography className="mb-3">Contact information</Typography>
      <TextField
        name="phone"
        label="Phone"
        type="number"
        inputProps={{ maxLength: 9 }}
        InputProps={{
          startAdornment: <InputAdornment position="start">+63</InputAdornment>,
        }}
      />
      <TextField name="email" label="Email" type="Email" disabled={!!contactDetailsInitialValues.email} />
    </>
  )
}

export default connect(ContactDetails);
