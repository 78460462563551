import React from 'react';
import { useRouter } from '../../components/hooks/useRouter';
import CustomerList from './customerList';
import CustomerAdd from './customerAdd';
import CustomerProfileView from './CustomerProfileView';

const Inventory = () => {
  const { match: { params } } = useRouter();
  const id = params.id;
  console.log('params', params);
  switch (params.page) {
    case 'add':
      return <CustomerAdd />
    case 'view':
      return <CustomerProfileView id={id} />
    case 'update':
        return <CustomerAdd id={id} />
    default:
      return <CustomerList />
  }
}

export default Inventory;
