import { useState, useMemo } from 'react';
import uuid from 'uuid';
import _filter from 'lodash/filter';
import _map from 'lodash/map';
import _first from 'lodash/first';
import { flattenObject } from '../../utils/objects';

const generateId = () => uuid().substring(0, 8);

const mockList = [
  {
    id: generateId(),
    resource: 'Camera',
    description: 'Minim adipisci offic',
    quantity: 100,
    coverPhoto: '',
    galleryPhoto: [],
    price: 500,
    priceUnit: "quantity",
  }, {
    id: generateId(),
    resource: 'Car',
    description: 'Quo anim id adipisc',
    quantity: 50,
    coverPhoto: '',
    galleryPhoto: [],
    price: 2000,
    priceUnit: "quantity",
  }, {
    id: generateId(),
    resource: 'Projector',
    description: 'Aut voluptates nisi ',
    quantity: 10,
    coverPhoto: '',
    galleryPhoto: [],
    price: 1000,
    priceUnit: "quantity",
  }
]

const useInventory = () => {
  const [list, setList] = useState(mockList);
  const [loading, setLoading] = useState();

  const saveItem = item => {
    setLoading(true);
    return new Promise(resolve => {
      const newItem = { ...flattenObject(item), id: generateId() };
      setList(list.push(newItem));
      setLoading(false);
      resolve(item);
    })
  }

  const getItemDetails = id => {
    return new Promise(resolve => {
      const item = _filter(list, { id });
      resolve(_first(item));
    })
  }

  const deleteItem = id => {
    return new Promise(resolve => {
      const newList = _filter(list, i => i.id !== id);
      setList(newList);
      resolve();
    })
  }

  const resourceAsOption = useMemo(() => {
    return _filter(_map(list, ({ id, resource, quantity }) => ({
      label: `${resource} (${quantity})`,
      value: id,
      quantity,
    })), item => item.quantity > 0)
  }, [list])

  return { loading, list, saveItem, getItemDetails, deleteItem, resourceAsOption }
}

export default useInventory
