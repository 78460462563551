import _keys from 'lodash/keys';
import _each from 'lodash/each';
import _get from 'lodash/get';

export const flattenObject = (obj) => {
  let tmp = {};
  _each(_keys(obj), key => {
    tmp = { ...tmp, ..._get(obj, key, {}) };
  })        
  return tmp;
}

export const preFillObject = (initial, filled) => {
  let data = {};
  _each(_keys(initial), key => {
    data[key] = _get(filled, key, initial[key]);
  })
  return data;
}
