import React from 'react';
import { useRouter } from '../../components/hooks/useRouter';
import InventoryList from './inventoryList';
import InventoryAdd from './inventoryAdd';
import InventoryItemView from './inventoryItemView';

const Inventory = () => {
  const { match: { params } } = useRouter();
  const id = params.id;
  console.log('params', params);
  switch (params.page) {
    case 'add':
      return <InventoryAdd />
    case 'view':
      return <InventoryItemView id={id} />
    case 'update':
        return <InventoryAdd id={id} />
    default:
      return <InventoryList />
  }
}

export default Inventory;
