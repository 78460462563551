import React from 'react';
import { useRouter } from '../../components/hooks/useRouter';
import ReservationList from './reservationList';
import NewReservation from './newReservation';
import ReservationDetailDisplay from './viewReservationDetails';

const Inventory = () => {
  const { match: { params } } = useRouter();
  const id = params.id;
  console.log('params', params);
  switch (params.page) {
    case 'new':
      return <NewReservation />
    case "view": 
      return <ReservationDetailDisplay id={id} />
    default:
      return <ReservationList />
  }
}

export default Inventory;
